import React, {Component} from 'react';
import {Helmet} from "react-helmet";
import styles from './Home.module.scss';
import {Link} from "react-router-dom";
import Slider from "react-slick";
import {apiCall} from "../helpers/networkHelper";
import {MASTER_PAGE_HOME} from "../utils/endpoints";
import ErrorHandler from "../classes/ErrorHandler";
import {empty} from "../helpers/generalHelper";
import ModuleNewsletter from "./ModuleNewsletter";

class Home extends Component {
    constructor(props) {
        super(props);

        this.state = {
            homeData: null,
            categories: null,
            imageSliders: null,
            mobileMenuOpened: false
        }
    }

    componentDidMount() {
        document.getElementById("loader-wrapper").style = "display: flex";
        document.getElementById("loader").style = "display: block";

        apiCall(
            MASTER_PAGE_HOME,
            'get',
            null,
            res => {
                this.setState({
                    homeData: res.data.home_data,
                    categories: res.data.categories,
                    imageSliders: res.data.image_sliders
                }, () => {
                    document.getElementById("loader-wrapper").style = "display: none";
                });
            },
            err => {
                ErrorHandler.handleGeneralError(err);
            },
            res => {
                // this.hideLoader();
            }
        );
        // setTimeout(function () {
        //     document.getElementById("loader-wrapper").style = "display: none";
        // }, 2000);
    }

    render() {
        const {homeData, imageSliders} = this.state;
        const sliderSettings = {
            dots: false,
            arrows: false,
            infinite: true,
            swipe: false,
            pauseOnHover: false,
            fade: true,
            autoplay: true,
            autoplaySpeed: 8000,
            speed: 600,
            slidesToShow: 1,
            slidesToScroll: 1,
            cssEase: "cubic-bezier(0.87, 0.03, 0.41, 0.9)"
        };

        return (
            <React.Fragment>
                <Helmet>
                    <title>Nail | PT Warna Mardhika</title>
                    <meta name="description"
                          content="The second brand of PT Warna Mardhika present in 1995, namely NAIL. NAIL focuses on the uniqueness of modern men's shirt is devoted for them who casual style, but still looks exclusive."/>
                </Helmet>

                {!empty(imageSliders) && (
                    <>
                        <section className={styles.sliderSection}>
                            <Slider {...sliderSettings} className={styles.slider}>
                                {imageSliders.map(image => {
                                    if (image.slider_type === 'IMAGE') {
                                        return (
                                            <div key={`DesktopSlider${image.id}`} className="item image">
                                                <a href={image.href_url}
                                                   target={image.open_new_tab === 1 ? '_blank' : '_self'}>
                                                    <img src={image.media_url} alt="Slider Image"/>
                                                </a>
                                            </div>
                                        );
                                    }

                                    if (image.slider_type === 'YOUTUBE') {
                                        return (
                                            <div key={`DesktopSlider${image.id}`} className="item youtube">
                                                <iframe className="embed-player slide-media"
                                                        src={`https://www.youtube.com/embed/${image.youtube_video_id}?enablejsapi=1&controls=0&fs=0&iv_load_policy=3&rel=0&showinfo=0&loop=1&playlist=${image.youtube_video_id}&start=1&autoplay=1&disablekb=1&mute=1&modestbranding=1&rel=0`}
                                                        frameBorder="0"
                                                        allowFullScreen
                                                />
                                            </div>
                                        );
                                    }
                                })}
                            </Slider>
                        </section>

                        <section className={styles.sliderSectionMobile}>
                            <Slider {...sliderSettings} className={styles.slider}>
                                {imageSliders
                                    .filter(image => image.slider_type === 'IMAGE')
                                    .map(image => (
                                        <div key={`MobileSlider${image.id}`} className="item image">
                                            <a href={image.href_url}
                                               target={image.open_new_tab === 1 ? '_blank' : '_self'}>
                                                <img src={image.media_url_mobile} alt="Slider Image"/>
                                            </a>
                                        </div>
                                    ))
                                }
                            </Slider>
                        </section>
                    </>
                )}

                <div style={{position: 'sticky', top: 0}}>
                    <section className={styles.row1}>
                        <div className={styles.col1}>
                            <img src={homeData?.HOME_SECTION_1_IMAGE_DESKTOP} alt="Banner"/>
                        </div>
                        <div className={styles.col2}>
                            <div className={styles.thinTitle}>
                                {homeData?.HOME_SECTION_1_TITLE_LINE_1}
                            </div>
                            <div className={styles.thickTitle}>
                                {homeData?.HOME_SECTION_1_TITLE_LINE_2}
                            </div>
                            <div className={styles.subtitle}>
                                {homeData?.HOME_SECTION_1_SUBTITLE}
                            </div>

                            {!empty(homeData?.HOME_SECTION_1_ACTION_BUTTON_TEXT) && (
                                <div>
                                    {homeData?.HOME_SECTION_1_ACTION_BUTTON_NEW_TAB === 1 && (
                                        <a href={homeData?.HOME_SECTION_1_ACTION_BUTTON_URL}
                                           target="_blank"
                                           rel="noopener noreferrer"
                                           className={styles.actionBtn}
                                        >{homeData?.HOME_SECTION_1_ACTION_BUTTON_TEXT}</a>
                                    )}

                                    {homeData?.HOME_SECTION_1_ACTION_BUTTON_NEW_TAB !== 1 && (
                                        <a href={homeData?.HOME_SECTION_1_ACTION_BUTTON_URL}
                                           className={styles.actionBtn}
                                        >{homeData?.HOME_SECTION_1_ACTION_BUTTON_TEXT}</a>
                                    )}

                                </div>
                            )}
                        </div>
                    </section>

                    <section className={styles.row2}>
                        <div className={styles.col1}>
                            <div className={styles.thinTitle}>
                                {homeData?.HOME_SECTION_2_TITLE_LINE_1}
                            </div>
                            <div className={styles.thickTitle}>
                                {homeData?.HOME_SECTION_2_TITLE_LINE_2}
                            </div>
                            <div className={styles.subtitle}>
                                {homeData?.HOME_SECTION_2_SUBTITLE}
                            </div>

                            {!empty(homeData?.HOME_SECTION_2_ACTION_BUTTON_TEXT) && (
                                <div>
                                    {homeData?.HOME_SECTION_2_ACTION_BUTTON_NEW_TAB === 1 && (
                                        <a href={homeData?.HOME_SECTION_2_ACTION_BUTTON_URL}
                                           target="_blank"
                                           rel="noopener noreferrer"
                                           className={styles.actionBtn}
                                        >{homeData?.HOME_SECTION_2_ACTION_BUTTON_TEXT}</a>
                                    )}

                                    {homeData?.HOME_SECTION_2_ACTION_BUTTON_NEW_TAB !== 1 && (
                                        <a href={homeData?.HOME_SECTION_2_ACTION_BUTTON_URL}
                                           className={styles.actionBtn}
                                        >{homeData?.HOME_SECTION_2_ACTION_BUTTON_TEXT}</a>
                                    )}

                                </div>
                            )}
                        </div>
                        <div className={styles.col2}>
                            <img src={homeData?.HOME_SECTION_2_IMAGE_DESKTOP} alt="Banner"/>
                        </div>
                    </section>

                    <section className={styles.row1}>
                        <div className={styles.col1}>
                            <img src={homeData?.HOME_SECTION_3_IMAGE_DESKTOP} alt="Banner"/>
                        </div>
                        <div className={styles.col2}>
                            <div className={styles.thinTitle}>
                                {homeData?.HOME_SECTION_3_TITLE_LINE_1}
                            </div>
                            <div className={styles.thickTitle}>
                                {homeData?.HOME_SECTION_3_TITLE_LINE_2}
                            </div>
                            <div className={styles.subtitle}>
                                {homeData?.HOME_SECTION_3_SUBTITLE}
                            </div>

                            {!empty(homeData?.HOME_SECTION_3_ACTION_BUTTON_TEXT) && (
                                <div>
                                    {homeData?.HOME_SECTION_3_ACTION_BUTTON_NEW_TAB === 1 && (
                                        <a href={homeData?.HOME_SECTION_3_ACTION_BUTTON_URL}
                                           target="_blank"
                                           rel="noopener noreferrer"
                                           className={styles.actionBtn}
                                        >{homeData?.HOME_SECTION_3_ACTION_BUTTON_TEXT}</a>
                                    )}

                                    {homeData?.HOME_SECTION_3_ACTION_BUTTON_NEW_TAB !== 1 && (
                                        <a href={homeData?.HOME_SECTION_3_ACTION_BUTTON_URL}
                                           className={styles.actionBtn}
                                        >{homeData?.HOME_SECTION_3_ACTION_BUTTON_TEXT}</a>
                                    )}

                                </div>
                            )}
                        </div>
                    </section>

                    <section className={styles.hashtagSection}>
                        <h2><Link to='/hashtag'>#nailclothing</Link></h2>
                        <p>
                            Share your personal style and see how others are styling their favourites from Nail.<br/>Tag
                            your picture with @nailclothingbrand and #nailclothing for a chance to be featured on
                            nail-clothing.com
                            and in our stores.
                        </p>
                        <div className={styles.hashtagPhotos}>
                            <div className={styles.hashtagPhoto}>
                                <a href="https://www.instagram.com/nailclothingbrand/" target="_blank"
                                   rel="noopener noreferrer">
                                    <img src={`${process.env.PUBLIC_URL}/assets/images/wm/nl/hashtag/hashtag01.jpeg`}
                                         alt="HashTag Photo"/>
                                    <div className={styles.hashtagPhotoOverlay}>
                                        <div className={styles.hashtagPhotoText}>
                                            @nailclothingbrand
                                        </div>
                                    </div>
                                </a>
                            </div>

                            <div className={styles.hashtagPhoto}>
                                <a href="https://www.instagram.com/nailclothingbrand/" target="_blank"
                                   rel="noopener noreferrer">
                                    <img src={`${process.env.PUBLIC_URL}/assets/images/wm/nl/hashtag/hashtag02.jpeg`}
                                         alt="HashTag Photo"/>
                                    <div className={styles.hashtagPhotoOverlay}>
                                        <div className={styles.hashtagPhotoText}>
                                            @nailclothingbrand
                                        </div>
                                    </div>
                                </a>
                            </div>

                            <div className={styles.hashtagPhoto}>
                                <a href="https://www.instagram.com/nailclothingbrand/" target="_blank"
                                   rel="noopener noreferrer">
                                    <img src={`${process.env.PUBLIC_URL}/assets/images/wm/nl/hashtag/hashtag03.jpeg`}
                                         alt="HashTag Photo"/>
                                    <div className={styles.hashtagPhotoOverlay}>
                                        <div className={styles.hashtagPhotoText}>
                                            @nailclothingbrand
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </section>

                    <ModuleNewsletter/>

                    {/*<FooterOne logoName={'logo.png'}/>*/}
                </div>
            </React.Fragment>
        );
    }
}

export default Home;