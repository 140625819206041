import React, {Component} from 'react';
import {Link, NavLink} from 'react-router-dom';
import {IntlActions} from 'react-redux-multilingual';
import {message} from "antd";
import Pace from 'react-pace-progress';

// Import custom components
import store from '../store';
import NavBar from "./common/headers/common/navbar";
import SideBar from "./common/headers/common/sidebar";
import CartContainer from "./../containers/CartContainer";
import TopBar from "./common/headers/common/topbar";
import LogoImage from "./common/headers/common/logo";
import {changeCurrency, closeCart, openCart, retrieveCart} from '../actions'
import {connect} from "react-redux";
import styles from './Header.module.scss';
import {empty} from "../helpers/generalHelper";
import {apiCall} from "../helpers/networkHelper";
import {MASTER_PAGE_HOME} from "../utils/endpoints";
import ErrorHandler from "../classes/ErrorHandler";

import {OffCanvas, OffCanvasMenu, OffCanvasBody} from "react-offcanvas";
import {number_format} from "../helpers/stringHelper";

class Header extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            mobileMenuOpened: false,
            categories: null,
            activeMobileCategoryId: null,
        }
    }

    /*=====================
         Pre loader
         ==========================*/
    componentDidMount() {
        document.getElementById("loader-wrapper").style = "display: flex";
        document.getElementById("loader").style = "display: block";

        setTimeout(function () {
            if (document.querySelector(".loader-wrapper").style.display !== 'none') {
                document.querySelector(".loader-wrapper").style = "display: none";
                window.scrollTo(0, 0);
            }
        }, 2000);

        this.setState({open: true});
        this.props.retrieveCart();

        apiCall(
            MASTER_PAGE_HOME,
            'get',
            null,
            res => {
                this.setState({
                    categories: res.data.categories
                }, () => {
                });
            },
            err => {
                ErrorHandler.handleGeneralError(err);
            },
            res => {
            }
        );
    }

    /*componentWillMount(){
        window.addEventListener('scroll', this.handleScroll);
	}
    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = () => {
        let number = window.pageXOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
        if (number >= 300) {
            if (window.innerWidth < 576) {
                document.getElementById("sticky").classList.remove('fixed');
            }else
            	document.getElementById("sticky").classList.add('fixed');
        } else {
            document.getElementById("sticky").classList.remove('fixed');
        }
    }*/

    changeLanguage(lang) {
        store.dispatch(IntlActions.setLocale(lang))
    }

    openNav() {
        var openmyslide = document.getElementById("mySidenav");
        if (openmyslide) {
            openmyslide.classList.add('open-side')
        }
    }

    openSearch() {
        document.getElementById("search-overlay").style.display = "block";
    }

    closeSearch() {
        document.getElementById("search-overlay").style.display = "none";
    }

    load = () => {
        this.setState({isLoading: true});
        fetch().then(() => {
            // deal with data fetched
            this.setState({isLoading: false})
        })
    };

    renderMegaMenu = () => {
        const {categories} = this.state;

        if (!empty(categories)) {
            return (
                <div className={styles.dropdownContent}>
                    <div className={styles.separator}>
                    </div>
                    <div className={styles.row}>
                        {categories.map((category, index) => {
                            return (
                                <div className={styles.column} key={index}>
                                    <a href={`/products?category_id=${category.id}`}>{category.name}</a>
                                    <ul>
                                        {category.category_subs.map((category_sub, index) => {
                                            return (
                                                <li key={index}><a
                                                    href={`/products?category_sub_id=${category_sub.id}`}>{category_sub.name}</a>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </div>
                            );
                        })}
                    </div>
                </div>
            );
        }
    }

    renderMobileMenu = () => {
        const {categories, activeMobileCategoryId} = this.state;

        if (!empty(categories)) {
            return (
                <div className={styles.mobileMenuList}>
                    {categories.map((category, index) => {
                        return (
                            <div className={`${styles.column} ${styles.dropdown}`} key={index}>
                                <a className={category.id === activeMobileCategoryId ? styles.active : ''}
                                   href="#"
                                   onClick={() => {
                                       this.setState({
                                           activeMobileCategoryId: category.id === activeMobileCategoryId ? null : category.id
                                       });
                                   }}
                                >
                                    {category.name}
                                </a>
                                <ul className={category.id === activeMobileCategoryId ? styles.active : ''}>
                                    <li><a href={`/products?category_id=${category.id}`}>Shop All</a></li>
                                    {category.category_subs.map((category_sub, index) => {
                                        return (
                                            <li key={index}><a
                                                href={`/products?category_sub_id=${category_sub.id}`}>{category_sub.name}</a>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>

                        );
                    })}

                    <div className={styles.column}>
                        <Link to="/stores"
                              onClick={() => {
                                  this.setState({
                                      activeMobileCategoryId: null
                                  });
                              }}
                        >
                            STORES
                        </Link>
                    </div>

                    <div className={styles.column}>
                        <a href="https://nailclothingbrand.tumblr.com/"
                           target={"_blank"}
                           rel="noopener noreferrer"
                           onClick={() => {
                               this.setState({
                                   activeMobileCategoryId: null
                               });
                           }}
                        >
                            NEWS
                        </a>
                    </div>

                    <div className={`${styles.column} ${styles.dropdown}`}>
                        <a className={ activeMobileCategoryId === 'SALE' ? styles.active : ''}
                           href="#"
                           onClick={() => {
                               this.setState({
                                   activeMobileCategoryId: activeMobileCategoryId === 'SALE' ? null : 'SALE'
                               });
                           }}
                        >
                            SALE
                        </a>

                        <ul className={ activeMobileCategoryId === 'SALE' ? styles.active : ''}>
                            <li><a href={`/products?sale=1`}>Shop All</a></li>
                            {categories.map((category, index) => {
                                return (
                                    <li key={`MobileSaleCategory${index}`}><a
                                        href={`/products?category_id=${category.id}&sale=1`}>Shop {category.name} Sale</a></li>
                                );
                            })}
                        </ul>
                    </div>
                </div>
            );
        }
    }

    render() {

        const onlineCart = this.props.onlineCart;
        const subTotal = onlineCart?.cart_items?.reduce((total, cartItem) => {
            return total + (cartItem.qty * parseFloat(cartItem.product_detail.net_price))
        }, 0) || 0;

        const topAdsText = "Sale up to 80% off!";

        return (
            <div>
                <div className={`${styles.offCanvasOverlay} ${this.props.cartOpened ? styles.active : ''}`}
                     onClick={() => {
                         this.props.closeCart();
                     }}
                />
                <div className={`${styles.offCanvas} ${this.props.cartOpened ? styles.active : ''}`}>
                    <div className={styles.btnRemove}><i className={`fa fa-remove`} onClick={() => {
                        this.props.closeCart();
                    }}/></div>

                    {empty(this.props.onlineCart?.cart_items) && (
                        <div className={`${styles.cartItems} ${styles.cartItemsEmpty}`}>
                            Your cart is empty
                            <br/>
                            <a href={'/products'} className={`btn btn-solid`}>Continue Shopping</a>
                        </div>
                    )}

                    {!empty(this.props.onlineCart?.cart_items) && (
                        <div className={`${styles.cartItems}`}>
                            {this.props.onlineCart?.cart_items?.map(item => {
                                const productName = item.product_detail.product_name;
                                const coverImage = item.product_detail.product_images.find(row => row.image_type === 'COVER')?.image_url || item.product_detail.product_images[0]?.image_url;
                                const size = item.size_code;
                                const qty = item.qty;
                                const price = number_format(item.product_detail.net_price, 0);

                                return (
                                    <div className={styles.cartItem}>
                                        <div className={styles.cartItemImage}>
                                            <img src={coverImage} alt={productName}/>
                                        </div>
                                        <div className={styles.cartItemInfo}>
                                            <div className={styles.itemName}>
                                                {productName}
                                            </div>
                                            <div className={styles.itemSize}>
                                                Size: {size}
                                            </div>
                                            <div className={styles.itemPrice}>
                                                {qty} x IDR {price}
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    )}

                    <div className={styles.cartSubtotal}>
                        Subtotal IDR {number_format(subTotal, 0)}
                    </div>

                    <div className={styles.cartActions}>
                        <a className={`btn btn-solid btn-block`} href={'/cart'}>Go To Cart</a>
                        {/*<Link className={`btn btn-solid btn-block`} to={'/checkout'}>Checkout Now</Link>*/}
                    </div>
                </div>

                <header className={styles.headerSection}>
                    <div className={styles.promoBar}>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <strong>{topAdsText}</strong>
                                    {/*Saat ini pengiriman online sedang libur, kembali normal tanggal 4 Januari 2021.*/}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={styles.navBar}>
                        <div className={styles.navBarMenuMobile}>
                            <div>
                                <a href="#"
                                   onClick={() => {
                                       this.setState({mobileMenuOpened: true});
                                   }}
                                ><i className="fa fa-bars"/></a>
                            </div>

                            <div>
                                <a href="#" onClick={this.openSearch}>
                                    <img
                                        src={`${process.env.PUBLIC_URL}/assets/images/wm/icon-search.png`}
                                        srcSet={`${process.env.PUBLIC_URL}/assets/images/wm/icon-search@2x.png 2x`}
                                        alt="Search"/>
                                </a>
                            </div>
                        </div>

                        <div className={styles.mobileMenu}
                             style={{display: this.state.mobileMenuOpened ? 'block' : 'none'}}>
                            <div className={styles.promoBar}>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <strong>{topAdsText}</strong>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.closeBtn}
                                 onClick={() => {
                                     this.setState({
                                         mobileMenuOpened: false
                                     });
                                 }}
                            >
                                {/*<i className="fa fa-remove"
								   onClick={() => {
									   this.setState({
										   mobileMenuOpened: false
									   });
								   }}
								/>*/}
                            </div>

                            <div className={styles.mobileHeaderLogo}>
                                <Link to="/">
                                    <img
                                        src={`${process.env.PUBLIC_URL}/assets/images/wm/logo-nail.png`}
                                        // srcSet={`${process.env.PUBLIC_URL}/assets/images/wm/logo-hammer@2x.png 2x`}
                                        className="img-fluid" alt="Nail"/>
                                </Link>
                            </div>

                            <div className={styles.mobileHeaderMenu}>
                                {this.renderMobileMenu()}
                            </div>
                        </div>

                        <div className={styles.navBarMenu}>
                            <ul>
                                <li className={styles.dropdown}>
                                    <a href={`/products?category_id=9`}>TOPS</a>
                                    {this.renderMegaMenu()}
                                </li>
                                <li className={styles.dropdown}>
                                    <a href={`/products?category_id=11`}>BOTTOMS</a>
                                    {this.renderMegaMenu()}
                                </li>
                                <li>
                                    <Link to="/stores">STORES</Link>
                                </li>
                                <li><a href="https://nailclothingbrand.tumblr.com/" target="_blank"
                                       rel="noopener noreferrer">NEWS</a></li>
                                <li className={styles.saleDropdown}>
                                    <a href="/products?sale=1" className={styles.highlighted}>SALE</a>

                                    <div className={styles.saleDropdownContent}>
                                        <div className={styles.separator}>
                                        </div>
                                        <div className={styles.row}>
                                            <div className={styles.column}>
                                                <a href={`/products?sale=1`}>Sale</a>
                                                <ul>
                                                    {this.state.categories?.map((category, index) => {
                                                        return (
                                                            <li key={index}><a
                                                                href={`/products?category_id=${category.id}&sale=1`}>Shop {category.name}'s
                                                                Sale</a></li>
                                                        );
                                                    })}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>

                        <div className={styles.navBarLogo}>
                            <Link to="/">
                                <img
                                    src={`${process.env.PUBLIC_URL}/assets/images/wm/logo-nail.png`}
                                    // srcSet={`${process.env.PUBLIC_URL}/assets/images/wm/logo-hammer@2x.png 2x`}
                                    alt="Nail"/>

                            </Link>
                        </div>

                        <div className={styles.navBarRight}>
                            <div className={styles.search}>
                                {/*<a href="#" onClick={this.openSearch}><i className="fa fa-search"/></a>*/}
                                <a href="#" onClick={this.openSearch}>
                                    <img
                                        src={`${process.env.PUBLIC_URL}/assets/images/wm/icon-search.png`}
                                        srcSet={`${process.env.PUBLIC_URL}/assets/images/wm/icon-search@2x.png 2x`}
                                        alt="Search"/>
                                </a>
                            </div>
                            <div>
                                {/*<Link to={this.props.authenticated ? '/account' : '/sign-in'}><i className="fa fa-user"/></Link>*/}
                                <Link to={this.props.authenticated ? '/account' : '/sign-in'}>
                                    <img
                                        src={`${process.env.PUBLIC_URL}/assets/images/wm/icon-user.png`}
                                        srcSet={`${process.env.PUBLIC_URL}/assets/images/wm/icon-user@2x.png 2x`}
                                        alt="Account"/>
                                </Link>
                            </div>
                            <div>
                                <a href={'#'} onClick={() => {
                                    this.props.openCart();
                                }}>
                                    {/*<Link to={`${process.env.PUBLIC_URL}/cart`}>*/}
                                    {/*<i className="fa fa-shopping-bag"/>*/}
                                    <img
                                        src={`${process.env.PUBLIC_URL}/assets/images/wm/icon-shopping-bag.png`}
                                        srcSet={`${process.env.PUBLIC_URL}/assets/images/wm/icon-shopping-bag@2x.png 2x`}
                                        alt="Cart"/>
                                </a>
                            </div>
                        </div>
                    </div>

                    {false && (
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className={styles.mobileMenu}
                                         style={{display: this.state.mobileMenuOpened ? 'block' : 'none'}}>
                                        <div className={styles.closeBtn}>
                                            <i className="fa fa-remove"
                                               onClick={() => {
                                                   this.setState({
                                                       mobileMenuOpened: false
                                                   });
                                               }}
                                            />
                                        </div>

                                        <div className={styles.mobileHeaderLogo}>
                                            <Link to="/">
                                                <img
                                                    src={`${process.env.PUBLIC_URL}/assets/images/wm/logo-coconut-long-red.png`}
                                                    className="img-fluid" alt="Coconut Island"/>
                                            </Link>
                                        </div>

                                        <div className={styles.mobileHeaderMenu}>
                                            <ul>
                                                <li><Link to={`${process.env.PUBLIC_URL}/shop/men`}>Men</Link></li>
                                                <li><Link to={`${process.env.PUBLIC_URL}/shop/women`}>Women</Link></li>
                                                <li><a href={`/products?group_id=3`}>Kids</a></li>
                                                <li><Link to={`${process.env.PUBLIC_URL}/products`}>Shop All</Link></li>
                                                <li><Link to={`${process.env.PUBLIC_URL}/hashtag`}>#iamCOCONUTs</Link>
                                                </li>
                                                <li>
                                                    <hr/>
                                                </li>
                                                <li>
                                                    {!this.props.authenticated && (
                                                        <Link to="/sign-in">Sign In</Link>
                                                    )}

                                                    {this.props.authenticated && (
                                                        <Link to="/account">My Account</Link>
                                                    )}

                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div className={styles.headerLogo}>
                                        <Link to="/">
                                            <img
                                                src={`${process.env.PUBLIC_URL}/assets/images/wm/logo-coconut-long-red.png`}
                                                alt="Coconut Island"/>
                                        </Link>
                                    </div>

                                    <div className={styles.headerMenuToggle}>
                                        <div className={`mobile-search ${styles.mobileSearch}`}>
                                            <img src={`${process.env.PUBLIC_URL}/assets/images/icon/search.png`}
                                                 onClick={this.openSearch} className="img-fluid" alt=""/>
                                            {/*<i className={`fa fa-search ${styles.cartIcon}`}/>*/}
                                        </div>

                                        <div className={`mobile-cart ${styles.mobileCart}`}>
                                            <Link to={`${process.env.PUBLIC_URL}/cart`}>
                                                <div
                                                    className="cart-qty-cls">{this.props.onlineCart?.cart_items?.length || 0}</div>
                                                {/*<img src={`${process.env.PUBLIC_URL}/assets/images/icon/cart.png`} className="img-fluid" alt=""/>*/}
                                                <i className={`fa fa-shopping-basket ${styles.cartIcon}`}/>
                                            </Link>
                                        </div>

                                        <i className="fa fa-bars" onClick={() => {
                                            this.setState({
                                                mobileMenuOpened: true
                                            });
                                        }}/>
                                    </div>

                                    <div className={styles.headerMenu}>
                                        <ul>
                                            <li><Link to="/products">New Arrival</Link></li>
                                            <li><Link to="/shop/men">Men</Link></li>
                                            <li><Link to="/shop/women">Women</Link></li>
                                            <li><a href={`/products?group_id=3`}>Kids</a></li>
                                            {/*<li><Link to="/products">Sale</Link></li>*/}
                                            <li><Link to={`${process.env.PUBLIC_URL}/hashtag`}>#iamCOCONUTs</Link></li>
                                            {/*<li><a href="#">Coconut Island</a></li>
										<li><a href="#">Nail</a></li>*/}
                                        </ul>
                                    </div>
                                    <div className={styles.headerRight}>
                                        <div className="icon-nav">
                                            <ul>
                                                <li>
                                                    {!this.props.authenticated && (
                                                        <Link to="/sign-in">Sign In</Link>
                                                    )}

                                                    {this.props.authenticated && (
                                                        <Link to="/account">My Account</Link>
                                                    )}

                                                </li>

                                                <li className={styles.wishlistBtn}>
                                                    <div>
                                                        {/*<img src={`${process.env.PUBLIC_URL}/assets/images/icon/search.png`} onClick={this.openSearch} className="img-fluid" alt="" />*/}

                                                        {!this.props.authenticated && (
                                                            <Link onClick={() => {
                                                                message.error('Please sign in first to use wishlist');
                                                            }} to="/sign-in"><i className="fa fa-heart-o"/></Link>
                                                        )}

                                                        {this.props.authenticated && (
                                                            <Link to="/account/wishlist"><i
                                                                className="fa fa-heart-o"/></Link>
                                                        )}


                                                    </div>
                                                </li>

                                                <li className="onhover-div mobile-search">
                                                    <div><img
                                                        src={`${process.env.PUBLIC_URL}/assets/images/icon/search.png`}
                                                        onClick={this.openSearch} className="img-fluid" alt=""/>
                                                        {/*<i className="fa fa-search" onClick={this.openSearch}></i>*/}
                                                    </div>
                                                </li>

                                                {/*<li className="onhover-div mobile-setting">
														<div><img src={`${process.env.PUBLIC_URL}/assets/images/icon/setting.png`} className="img-fluid" alt="" />
															<i className="fa fa-cog"></i></div>
														<div className="show-div setting">
															<h6>language</h6>
															<ul>
																<li><a href={null} onClick={() => this.changeLanguage('en')}>English</a> </li>
																<li><a href={null} onClick={() => this.changeLanguage('fn')}>French</a> </li>
															</ul>
															<h6>currency</h6>
															<ul className="list-inline">
																<li><a href={null} onClick={() => this.props.changeCurrency('€')}>euro</a> </li>
																<li><a href={null} onClick={() => this.props.changeCurrency('₹')}>rupees</a> </li>
																<li><a href={null} onClick={() => this.props.changeCurrency('£')}>pound</a> </li>
																<li><a href={null} onClick={() => this.props.changeCurrency('$')}>doller</a> </li>
															</ul>
														</div>
													</li>*/}
                                                {/*Header Cart Component */}
                                                <CartContainer/>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </header>

                <div id="search-overlay" className="search-overlay">
                    <div>
                        <span className="closebtn" onClick={this.closeSearch} title="Close Overlay">×</span>
                        <div className="overlay-content">
                            <div className="container">
                                <div className="row">
                                    <div className="col-xl-12">
                                        <form action="/products" method="get" className={styles.searchForm}>
                                            <div className="form-group">
                                                <input type="text"
                                                       className="form-control"
                                                       id="searchInput"
                                                       name="search"
                                                       placeholder="Search a Product"/>
                                            </div>
                                            <button type="submit" className="btn btn-primary"><i
                                                className="fa fa-search"/></button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    authenticated: state.auth.authenticated,
    onlineCart: state.cartList.onlineCart,
    cartOpened: state.cartList.cartOpened
});

export default connect(mapStateToProps,
    {changeCurrency, retrieveCart, openCart, closeCart}
)(Header);