import React, {Component} from 'react';
import {Helmet} from "react-helmet";
import styles from './Tnc.module.scss';
import FooterOne from "./common/footers/footer-one";
import {svgFreeShipping, svgoffer, svgservice} from "../services/script";
import {Link} from "react-router-dom";
import Slider from "react-slick";
import {apiCall} from "../helpers/networkHelper";
import {MASTER_PAGE_HOME, MASTER_SHOWROOM_REGION} from "../utils/endpoints";
import ErrorHandler from "../classes/ErrorHandler";
import {empty} from "../helpers/generalHelper";
import ModuleNewsletter from "./ModuleNewsletter";

class Tnc extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>Nail | PT Warna Mardhika</title>
                    <meta name="description"
                          content="The second brand of PT Warna Mardhika present in 1995, namely NAIL. NAIL focuses on the uniqueness of modern men's shirt is devoted for them who casual style, but still looks exclusive."/>
                </Helmet>

                <section className={styles.titleSection}>
                    <h1 className={styles.title}>T&C</h1>
                    <p className={styles.subtitle}>Terms and Conditions</p>
                </section>

                <section className={`container ${styles.tncSection}`}>
                    <div className="row">
                        <div className="col-md-12">
                            <div className={styles.tncItem}>
                                Selamat datang di website www.nail-clothing.com<br />
                                <br />
                                <br />
                                Dengan mengakses atau menggunakan situs nail-clothing.com, berarti Anda menyetujui dan memahami semua isi dalam syarat dan ketentuan kami. Syarat dan ketentuan dapat berubah sewaktu-waktu tanpa ada pemberitahuan terlebih dahulu. Perubahan dari syarat dan ketentuan akan segera berlaku setelah dicantumkan di dalam situs nail-clothing.com .<br />
                                <br />
                                Jika pengguna tidak menerima dan menyetujui syarat dan ketentuan yang kami tentukan dalam perjanjian ini, maka kami anjurkan untuk tidak menggunakan dan mengakses situs ini lebih lanjut.<br />
                                <br />
                                Ketentuan Penggunaan Situs Saat mengunjungi dan menggunakan situs Nail, termasuk setiap fitur dan layanannya, setiap pengguna wajib untuk mematuhi ketentuan pengguna situs berikut ini: Akses situs ini hanya diperkenankan untuk keperluan dan kepentingan berbelanja dan informasi terkait dengan layanan situs ini.<br />
                                <br />
                                Pengguna tidak diperkenankan untuk mereproduksi, mendistribusikan, memajang, menjual, menyewakan, mengirimkan, membuat karya turunan dari, menerjemahkan, memodifikasi, merekayasa balik, membongkar, mengurai atau mengeksploitasi situs nail-clothing.com.<br />
                                <br />
                                Pengguna tidak diperkenankan untuk memuat dan menerbitkan konten yang melanggar hak cipta, paten, merek dagang, merek layanan, rahasia dagang, atau hak kepemilikan lainnya.<br />
                                <br />
                                Seluruh komentar / review yang dituliskan, dan gambar/foto/image yang di upload di website nail-clothing.com akan dikategorikan dan diperlakukan sebagai konten yang bersifat tidak rahasia. Anda dilarang untuk menuliskan komentar atau gambar/foto/image yang mengandung kalimat/analogi pelecehan terhadap nail-clothing.com atau pihak ketiga lainnya/masyarakat. Pihak kami berhak merubah atau menghapus komentar dan gambar/foto/image yang mengandung unsur yang tidak layak tayang (SARA, Pornografi, Pelecehan, Penghinaan, Pencemaran, dsb).<br />
                                <br />
                                Transaksi Harga yang tertera di website nail-clothing.com belum termasuk Ongkos Kirim, Tarif Armada ataupun kurir ke luar kota (ekspedisi).<br />
                                <br />
                                Team Nail akan memeriksa kondisi produk sebelum dikirim kepada customer. Jika terjadi kerusakan pada barang semasa dalam perjalanan, segera hubungi kami dalam waktu 2x24jam, ikuti proses pengembalian barang dan email ke: ecommerce@warna-mardhika.com. Kami tidak menerima pengembalian barang atau produk jika sudah digunakan atau dipakai.<br />
                                <br />
                                Pelanggan yang melakukan order online melalui website kami harap memperhatikan isian untuk kolom quantity, size ataupun warna. Uang pemesan tidak diganti jika customer menyatakan kirimannya tidak diterima, tetapi kami bersama jasa pengirimannya telah mempunyai bukti bahwa barang telah diterima berupa tanda tangan dan nama jelas penerima.<br />
                                <br />
                                Produk-produk yang telah dibeli, tidak dapat dikembalikan/ditukar/diganti. Produk yang telah didiskon kualitasnya sama dengan produk kami lainnya, dan telah dicek oleh petugas kami sebelum dikirimkan ke alamat pembeli. Barang yang didiscount ini kami sediakan khusus untuk anda dengan paket harga yang menarik.<br />
                                <br />
                                Pengiriman barang akan kami proses di hari yang sama, untuk customer yang melakukan pembayaran dibawah jam 8 pagi (08.00 WIB). Waktu tempuh pengiriman disesuaikan dengan daerah tujuan pengiriman.<br />
                                <br />
                                Anda akan menerima email notifikasi mengenai pembelian anda dan nomor resi pengiriman sesaat setelah produk pesanan Anda dikirim.<br />
                                <br />
                                Produk pesanan Anda akan kami simpan maksimum 1 x 24 jam sebelum konfirmasi pembayaran, jika Anda belum melakukan pembayaran dalam waktu 1 x 24 jam maka Nail berhak menjual kembali produk pesanan Anda. Jika Anda telah melakukan pembayaran dengan produk yang Anda pesan, akan tetapi produk yang telah Anda bayar tidak tersedia, Anda berhak menukarkan dengan barang lain dengan jumlah nominal yang sama.<br />
                                <br />
                                Pengguna memahami dan menyetujui bahwa setiap permasalahan yang terjadi pada saat proses pengiriman barang oleh penyedia jasa layanan pengiriman Barang adalah merupakan tanggung jawab penyedia jasa layanan pengiriman.
                            </div>
                        </div>
                    </div>
                </section>

                <ModuleNewsletter />

            </React.Fragment>
        );
    }
}

export default Tnc;