import React, {Component} from 'react';
import Slider from 'react-slick';
import '../common/index.scss';
import {connect} from "react-redux";
// import custom Components
import RelatedProduct from "../common/related-product";
import Breadcrumb from "../common/breadcrumb";
import DetailsWithPrice from "./common/product/details-price";
import {addToCart, addToCartUnsafe, addToWishlist} from '../../actions'
import ImageZoom from './common/product/image-zoom'
import SmallImages from './common/product/small-image'
import {apiCall} from "../../helpers/networkHelper";
import {PRODUCT} from "../../utils/endpoints";
import {empty} from "../../helpers/generalHelper";
import {message, Spin} from "antd";
import styles from './NoSidebar.module.scss';
import {StickyShareButtons} from 'sharethis-reactjs';
import {number_format} from "../../helpers/stringHelper";

class NoSideBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nav1: null,
            nav2: null,
            item: {},
            relatedProducts: [],
            chosenIndex: 0,
            doAddToCart: 0
        };
    }

    componentDidMount() {
        this.setState({
            nav1: this.slider1,
            nav2: this.slider2
        });
        this.loadProductDetail();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.match.params.id !== this.props.match.params.id) {
            this.setState({ item: null });
            this.loadProductDetail();
        }
    }

    loadProductDetail = () => {
        apiCall(
            PRODUCT + `?id=${this.props.match.params.id}`,
            'get',
            null,
            (res) => {
                const row = res.data.product;

                const retailPrice = parseInt(row.retail_price);
                const netPrice = parseInt(row.net_price);
                const directDiscPercent = !empty(row.direct_discount)?parseFloat(row.direct_discount.direct_disc_percent):0;
                const directDiscNominal = !empty(row.direct_discount)?parseInt(row.direct_discount.direct_disc_nominal):0;
                const grandPrice = (netPrice * ((100-directDiscPercent)/100)).toFixed(0) - directDiscNominal;

                let coverImage = row.product_images[0]?.image_url;
                row.product_images.find(productImage => {
                    if(productImage.image_type === 'COVER'){
                        if(!empty(productImage.image_url)){
                            coverImage = productImage.image_url;
                        }
                    }
                });

                const product = {
                    ...row,
                    id: row.id,
                    name: row.product_name,
                    price: row.retail_price,
                    salePrice: row.net_price,
                    discount: Math.floor(((parseInt(row.retail_price) - parseInt(row.net_price)) / parseInt(row.retail_price)) * 100),
                    retailPrice,
                    netPrice,
                    directDiscPercent,
                    directDiscNominal,
                    grandPrice,
                    coverImage: coverImage,
                    pictures: row.product_images.map(product_image => product_image.image_url),
                    shortDetails: row.short_description,
                    description: row.detailed_description,
                    stock: 1,
                    new: false,
                    sale: false,
                    // category: "women"
                    // colors: (2) ["gray", "orange"]
                    size: row.sizes.map(size => size.size_code),
                    // tags: (2) ["caprese", "puma"]
                    rating: 5,
                    variants: row.sizes.map(size => {
                        return {
                            size: size.size_code,
                            images: !empty(row.product_images) ? row.product_images[0].image_url : ''
                        };
                    })
                };
                this.setState({item: product});

                const relatedProducts = row.related_products.map(relatedProduct => {
                    const retailPrice = parseInt(relatedProduct.retail_price);
                    const netPrice = parseInt(relatedProduct.net_price);
                    const directDiscPercent = !empty(relatedProduct.direct_discount)?parseFloat(relatedProduct.direct_discount.direct_disc_percent):0;
                    const directDiscNominal = !empty(relatedProduct.direct_discount)?parseInt(relatedProduct.direct_discount.direct_disc_nominal):0;
                    const grandPrice = (netPrice * ((100-directDiscPercent)/100)).toFixed(0) - directDiscNominal;

                    let coverImage = relatedProduct.product_images[0]?.image_url;
                    relatedProduct.product_images.find(productImage => {
                        if(productImage.image_type === 'COVER'){
                            if(!empty(productImage.image_url)){
                                coverImage = productImage.image_url;
                            }
                        }
                    });

                    return {
                        ...relatedProduct,
                        id: relatedProduct.id,
                        name: relatedProduct.product_name,
                        price: relatedProduct.retail_price,
                        salePrice: relatedProduct.net_price,
                        discount: Math.floor(((parseInt(relatedProduct.retail_price) - parseInt(relatedProduct.net_price)) / parseInt(relatedProduct.retail_price)) * 100),
                        retailPrice,
                        netPrice,
                        directDiscPercent,
                        directDiscNominal,
                        grandPrice,
                        coverImage,
                        pictures: relatedProduct.product_images.map(product_image => product_image.image_url),
                        shortDetails: relatedProduct.short_description,
                        description: relatedProduct.detailed_description,
                        stock: 1,
                        new: false,
                        sale: false,
                        // category: "women"
                        // colors: (2) ["gray", "orange"]
                        size: relatedProduct.sizes.map(size => size.size_code),
                        // tags: (2) ["caprese", "puma"]
                        rating: 5,
                        variants: relatedProduct.sizes.map(size => {
                            return {
                                size: size.size_code,
                                images: !empty(relatedProduct.product_images) ? relatedProduct.product_images[0].image_url : ''
                            };
                        })
                    };
                });
                this.setState({relatedProducts});
            },
            (res) => {
                message.error('Fail to retrieve product.');
            }
        );
    };

    render() {
        const {symbol, addToCart, addToCartUnsafe, addToWishlist} = this.props;
        const {item} = this.state;
        var products = {
            fade: true
        };

        var productsnav = {
            slidesToShow: 3,
            slidesToScroll: 1,
            swipeToSlide: true,
            draggable: true,
            focusOnSelect: true
        };


        return (
            <div style={{minHeight: '50vh', width: '100%'}}>
            <Spin style={{
                    textAlign: 'center',
                    display:'block',
                    marginTop: '20vh'
                }}
                  // tip="Loading product details.."
                  spinning={empty(item)}
                  indicator={
                      <img src={`${process.env.PUBLIC_URL}/assets/images/wm/nl/loading.png`}
                           style={{width: 80, height: 'auto'}}
                      />
                  }
            >
                {!empty(item) && (
                    <React.Fragment>
                        <div className={styles.fixedBar}>
                            <div className={styles.productName}>
                                {item.name}
                            </div>
                            <div className={styles.productPrice}>
                                <div className={styles.nominal}>
                                    IDR {number_format(item.grandPrice, 0)}
                                </div>
                                <button onClick={() => {
                                    window.scroll({
                                        top: 500,
                                        left: 0,
                                        behavior: 'smooth'
                                    });

                                    this.setState({doAddToCart: this.state.doAddToCart + 1})

                                    // message.warning('Please pick a size and click "Add to Cart" button below');
                                }}>Add to Cart</button>
                            </div>
                        </div>
                        {/*<StickyShareButtons
                            config={{
                                alignment: 'right',    // alignment of buttons (left, right)
                                color: 'social',      // set the color of buttons (social, white)
                                enabled: true,        // show/hide buttons (true, false)
                                font_size: 16,        // font size for the buttons
                                hide_desktop: false,  // hide buttons on desktop (true, false)
                                labels: 'counts',     // button labels (cta, counts, null)
                                language: 'en',       // which language to use (see LANGUAGES)
                                min_count: 0,         // hide react counts less than min_count (INTEGER)
                                networks: [           // which networks to include (see SHARING NETWORKS)
                                    'whatsapp',
                                    'facebook',
                                    'instagram',
                                    'twitter',
                                    'pinterest',
                                    'email'
                                ],
                                padding: 12,          // padding within buttons (INTEGER)
                                radius: 4,            // the corner radius on each button (INTEGER)
                                show_total: true,     // show/hide the total share count (true, false)
                                show_mobile: true,    // show/hide the buttons on mobile (true, false)
                                show_toggle: true,    // show/hide the toggle buttons (true, false)
                                size: 48,             // the size of each button (INTEGER)
                                top: 160,             // offset in pixels from the top of the page

                                // OPTIONAL PARAMETERS
                                url: 'https://www.sharethis.com', // (defaults to current url)
                                image: 'https://bit.ly/2CMhCMC',  // (defaults to og:image or twitter:image)
                                description: 'custom text',       // (defaults to og:description or twitter:description)
                                title: 'custom title',            // (defaults to og:title or twitter:title)
                                message: 'custom email text',     // (only for email sharing)
                                subject: 'custom email subject',  // (only for email sharing)
                                username: 'custom twitter handle' // (only for twitter sharing)

                            }}
                        />*/}
                        {/*<Breadcrumb title={' Product / ' + item.name}/>*/}

                        {/*Section Start*/}
                        {(item) ?
                            <section>
                                <div className="collection-wrapper">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-lg-6 product-thumbnail">
                                                {item.pictures.map((vari, index) => {
                                                    if(index === this.state.chosenIndex){
                                                        return (
                                                            <div key={index} style={{ width: '100%', height: '0', paddingBottom: '100%', overflow: 'hidden' }}>
                                                                <ImageZoom image={vari}
                                                                           className="img-fluid image_zoom_cls-0"/>
                                                            </div>
                                                        );
                                                    }
                                                })}
                                                <SmallImages item={item} settings={productsnav} onThumbnailClick={(x)=>{
                                                    this.setState({
                                                        chosenIndex: x
                                                    });
                                                }} navOne={this.state.nav1}/>
                                                {/*<Slider {...products} asNavFor={this.state.nav2}
                                                        ref={slider => (this.slider1 = slider)} className="product-slick">
                                                    {item.pictures.map((vari, index) =>
                                                        <div key={index} style={{width: '100%', height: '100%'}}>
                                                            <ImageZoom image={vari}
                                                                       className="img-fluid image_zoom_cls-0"/>
                                                        </div>
                                                    )}
                                                </Slider>
                                                <SmallImages item={item} settings={productsnav} navOne={this.state.nav1}/>*/}
                                            </div>
                                            <DetailsWithPrice symbol={symbol}
                                                              item={item}
                                                              navOne={this.state.nav1}
                                                              addToCartClicked={addToCart}
                                                              BuynowClicked={addToCartUnsafe}
                                                              addToWishlistClicked={addToWishlist}
                                                              doAddToCart={this.state.doAddToCart}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </section> : ''}
                        {/*Section End*/}

                        {!empty(this.state.item?.product_sizing) && (
                            <section>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <img style={{maxWidth: '100%'}} src={this.state.item?.product_sizing?.size_chart_url} alt="Size Guide" />
                                        </div>
                                    </div>
                                </div>
                            </section>
                        )}

                        {/*<section className="tab-product m-0">
                            <div className="container">
                                <div className="row">
                                    <div className="col-sm-12 col-lg-12">
                                        <DetailsTopTabs item={item}/>
                                    </div>
                                </div>
                            </div>
                        </section>*/}

                        {!empty(this.state.relatedProducts) && (
                            <RelatedProduct products={this.state.relatedProducts}/>
                        )}
                    </React.Fragment>
                )}
            </Spin>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    console.log(ownProps);
    let productId = ownProps.match.params.id;
    return {
        item: state.data.products.find(el => el.id == productId),
        symbol: state.data.symbol
    }
};

export default connect(mapStateToProps, {addToCart, addToCartUnsafe, addToWishlist})(NoSideBar);