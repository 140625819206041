import React, {Component} from 'react';
import {Helmet} from "react-helmet";
import styles from './Faq.module.scss';
import ModuleNewsletter from "./ModuleNewsletter";

class Faq extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeSection: "akun"
        };
    }

    onChangeSection = (sectionName) => {
        this.setState({activeSection: sectionName});
    }

    render() {
        const {activeSection} = this.state;

        return (
            <React.Fragment>
                <Helmet>
                    <title>Nail | PT Warna Mardhika</title>
                    <meta name="description"
                          content="The second brand of PT Warna Mardhika present in 1995, namely NAIL. NAIL focuses on the uniqueness of modern men's shirt is devoted for them who casual style, but still looks exclusive."/>
                </Helmet>

                <section className={styles.titleSection}>
                    <h1 className={styles.title}>Faq</h1>
                    <p className={styles.subtitle}>Frequently Asked Questions</p>
                </section>

                <section className={styles.menu}>
                    <div className={`container`}>
                        <div className={`row`}>
                            <div className={`col-md-12`}>
                                <ul>
                                    <li onClick={() => {this.onChangeSection("akun")}}
                                        className={activeSection==="akun"?styles.active:""}>
                                        <img src={`${process.env.PUBLIC_URL}/assets/images/wm/icon-faq-person.png`} alt={"Account"}/>
                                        <p>Account</p>
                                    </li>
                                    <li className={activeSection==="keranjang"?styles.active:""}
                                        onClick={() => {this.onChangeSection("keranjang")}}>
                                        <img src={`${process.env.PUBLIC_URL}/assets/images/wm/icon-faq-cart.png`} alt={"Keranjang Pembelian"}/>
                                        <p>Shopping Cart</p>
                                    </li>
                                    <li className={activeSection==="shipping"?styles.active:""}
                                        onClick={() => {this.onChangeSection("shipping")}}>
                                        <img src={`${process.env.PUBLIC_URL}/assets/images/wm/icon-faq-box.png`} alt={"Shipping & Handling"}/>
                                        <p>Shipping & Handling</p>
                                    </li>
                                    <li className={activeSection==="payment"?styles.active:""}
                                        onClick={() => {this.onChangeSection("payment")}}>
                                        <img src={`${process.env.PUBLIC_URL}/assets/images/wm/icon-faq-wallet.png`} alt={"Payment"}/>
                                        <p>Payment</p>
                                    </li>
                                    <li className={activeSection==="help"?styles.active:""}
                                        onClick={() => {this.onChangeSection("help")}}>
                                        <img src={`${process.env.PUBLIC_URL}/assets/images/wm/icon-faq-help.png`} alt={"Help"}/>
                                        <p>Help</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>

                <section className={`container ${styles.faqSection}`}>
                    <div className="row">
                        <div className="col-md-12">

                            {activeSection === 'akun' && (
                                <>
                                    <h2 className={styles.faqItemTitle} id={"akun"}>Account</h2>
                                    <div className={styles.faqItem}>
                                        <div className={styles.faqQuestion}>
                                            Q : Bagaimana cara registrasi akun?
                                        </div>
                                        <div className={styles.faqAnswer}>
                                            A : Pendaftaran sangat mudah. Kami hanya akan meminta anda memberikan informasi yang diperlukan untuk melakukan proses pembelian.<br/>
                                            Dalam formulir pendaftaran, anda dapat melihat contoh format yang dapat membantu anda saat memasukkan informasi seperti nama, nomor telepon seluler dan alamat email.
                                        </div>
                                    </div>

                                    <div className={styles.faqItem}>
                                        <div className={styles.faqQuestion}>
                                            Q : Apa kelebihan memiliki akun online di nail-clothing.com?
                                        </div>
                                        <div className={styles.faqAnswer}>
                                            A : Mendaftarkan akun online di <strong>nail-clothing.com</strong> akan membantu anda menemukan informasi pesanan anda.
                                        </div>
                                    </div>

                                    <div className={styles.faqItem}>
                                        <div className={styles.faqQuestion}>
                                            Q : Bagaimana cara merubah alamat dan data pribadi
                                        </div>
                                        <div className={styles.faqAnswer}>
                                            A  : untuk Perubahan Alamat,klik ‘Account Information’ pada halaman utama dan kemudian klik ‘Shipping Address’ lalu klik ‘Set as Primary’.
                                        </div>
                                    </div>

                                    <div className={styles.faqItem}>
                                        <div className={styles.faqQuestion}>
                                            Q : Bagaimana jika lupa kata sandi?
                                        </div>
                                        <div className={styles.faqAnswer}>
                                            A : Pada saat anda Login atau klik ‘Account Information’ terpadat menu ‘Forgot Password’, kemudian anda diminta memasukkan email yang terdaftar klik ‘Send new Password’.<br/>
                                            Anda akan menerima sebuah email yang berisi Password Baru, kemudian anda login dengan menggunakan password tersebut dan segera dilakukan perubahan password pada Menu ‘Account Information’ → ‘Change Password’

                                        </div>
                                    </div>
                                </>
                            )}


                            {activeSection === 'keranjang' && (
                                <>
                                    <h2 className={styles.faqItemTitle} id={"keranjang"}>Keranjang Pembelian</h2>
                                    <div className={styles.faqItem}>
                                        <div className={styles.faqQuestion}>
                                            Q: Bagaimana saya bisa berbelanja online di Nail-clothing.com?
                                        </div>
                                        <div className={styles.faqAnswer}>
                                            A : Anda dapat menelusuri website kami dengan memilih kategori yang anda inginkan. Kemudian, cukup pilih item yang ingin anda beli, pilih warna yang diinginkan, ukuran, dan jumlah yang akan dibeli, kemudian klik tombol ‘Add to Cart’.
                                            <br/><br/>
                                            Untuk berbelanja di nail-clothing.com anda harus melakukan registrasi akun terlebih dahulu.<br/>
                                            Jika anda sudah terdaftar menjadi member, maka anda dapat melanjutkan tahap ‘Checkout’ atau ‘Continue Shopping’ untuk menambah produk lain.
                                        </div>
                                    </div>

                                    <div className={styles.faqItem}>
                                        <div className={styles.faqQuestion}>
                                            Q : Dapatkah saya mengubah atau membatalkan pesanan?
                                        </div>
                                        <div className={styles.faqAnswer}>
                                            A : Dengan menyesal, kami tidak dapat mengubah atau membatalkan pesanan anda setelah anda menyelesaikan pesanan online. Pesanan yang sudah diproses tidak bisa dirubah atau dibatalkan.
                                        </div>
                                    </div>

                                    <div className={styles.faqItem}>
                                        <div className={styles.faqQuestion}>
                                            Q : Bagaimana jika barang yang diinginkan stok dan ukurannya sold out/tidak ada?
                                        </div>
                                        <div className={styles.faqAnswer}>
                                            A : Barang yang ukuran dan stock sold out, bisa jadi barang tersebut adalah best seller. Anda bisa menambahkan ke ‘Wishlist’ namun kami tidak menjamin ketersediaan stok tersebut.
                                        </div>
                                    </div>
                                </>
                            )}


                            {activeSection === 'shipping' && (
                                <>
                                    <h2 className={styles.faqItemTitle} id={"shipping"}>Shipping & Handling</h2>
                                    <div className={styles.faqItem}>
                                        <div className={styles.faqQuestion}>
                                            Q : Kapan barang pesanan diproses?
                                        </div>
                                        <div className={styles.faqAnswer}>
                                            A : Pesanan akan diproses dihari yang sama sesuai dengan jam operasional store yang dipilih.
                                            Jam operasional store : 10:00 – 20:00 WIB.
                                        </div>
                                    </div>

                                    <div className={styles.faqItem}>
                                        <div className={styles.faqQuestion}>
                                            Q : Apa saja pilihan metode pengiriman?
                                        </div>
                                        <div className={styles.faqAnswer}>
                                            A : Saat ini bekerjasama dengan SiCepat untuk pengiriman, metode pengiriman yang tersedia adalah:<br/>
                                            <ul>
                                                <li>
                                                    BEST (Besok Sampai Tujuan)<br/>
                                                    Barang cepat sampai tujuan hanya dengan estimasi 1 hari sampai di kota-kota besar Indonesia<br/>
                                                    Etd : 1 hari
                                                </li>
                                                <li>
                                                    SIUNTUNG<br/>
                                                    Layanan pengiriman cepat yang murah dengan harga reguler<br/>
                                                    Etd : 1-2 hari<br/>
                                                    Seluruh Indonesia (15 Jam - Khusus Wilayah Jabodetabek dan Bandung)
                                                </li>
                                                <li>
                                                    Same Day Service<br/>
                                                    Etd : 1 hari
                                                </li>
                                                <li>
                                                    Cargo (minimal 10kg)<br/>
                                                    Etd : 2-3 hari
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div className={styles.faqItem}>
                                        <div className={styles.faqQuestion}>
                                            Q : Bagaimana pelanggan dapat melacak status pesanan?
                                        </div>
                                        <div className={styles.faqAnswer}>
                                            A : Masuk ke menu ‘Account Information’ lalu klik ‘My Orders’ kemudian akan tampil semua item pesanan baik yang masih pending maupun yang sudah dilakukan pembayaran.<br/>
                                            Proses belanja/pemesanan yang sudah menyelesaikan transaksi pembayaran akan ada menu ‘Order Tracking’ pada saat klik anda diminta memasukkan nomor pesanan anda lalu akan muncul semua status atau history dari status pesanan anda.
                                        </div>
                                    </div>

                                    <div className={styles.faqItem}>
                                        <div className={styles.faqQuestion}>
                                            Q : Bagaimana jika pelanggan menerima barang rusak/cacat?
                                        </div>
                                        <div className={styles.faqAnswer}>
                                            A : Anda dapat mengembalikan barang yang telah dipesan dan diterima, tanpa dikenakan biaya tambahan atau biaya jasa selama anda telah menyelesaikan proses pembayaran pesanan anda, dengan syarat:<br/>
                                            <ul>
                                                <li>
                                                    Barang yang Anda terima tidak sesuai dengan yang pesanan, berdasarkan nama artikel dan warna barang.
                                                </li>
                                                <li>
                                                    Barang yang Anda terima cacat atau rusak.
                                                </li>
                                                <li>
                                                    Barang dapat dikembalikan dalam jangka waktu maksimal 3 hari setelah barang diterima.
                                                </li>
                                            </ul>

                                            <br/>
                                            Proses pengembalian:
                                            <ol>
                                                <li>
                                                    Hubungi Customer Service kami melalui Whatsapp di nomor : +62 811-879-191 dalam jangka waktu paling lambat 3 hari setelah barang di terima dengan menyertakan informasi sebagai berikut:
                                                    <ul>
                                                        <li>Nomor pesanan</li>
                                                        <li>Nama artikel</li>
                                                        <li>Alasan pengembalian</li>
                                                        <li>Lampiran foto barang dan video (*wajib  jika tidak menyertakan foto dan video, kami tidak dapat memproses pengembalian pesanan anda)</li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    Customer Service kami akan menghubungi anda kembali untuk mengkonfirmasi dan mengirimkan data retur yang harus dilengkapi beserta alamat lengkap pengiriman barang retur.
                                                </li>
                                                <li>
                                                    Lengkapi data retur dan kirimkan paket retur anda ke:<br/>
                                                    Hammer Club Official<br/>
                                                    d.a  Jl. Daan Mogot Km.13 No.17, Kec. Cengkareng, Kota Jakarta Barat, DKI Jakarta 11730.
                                                </li>
                                                <li>
                                                    Setelah paket retur kami terima dan diperiksa dan diproses oleh team ecommerce kami.
                                                </li>
                                                <li>
                                                    Semua barang yang dikembalikan dalam beberapa kondisi di bawah ini tidak akan kami proses dan kami akan mengirim kembali barang tersebut ke alamat pengiriman awal.
                                                    <ul>
                                                        <li>Dikirimkan kepada kami lebih dari 3 hari setelah barang diterima</li>
                                                        <li>Price tag dan hang tag sudah tidak terpasang lengkap pada barang tersebut</li>
                                                        <li>Kondisi barang sudah tidak baik (kecuali untuk retur karena item cacat/rusak) seperti berbau, rusak, atau terdapat indikasi lain bekas pakai pada barang tersebut.</li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    Estimasi paket retur tiba di gudang kami tergantung jasa pengiriman yang digunakan, diperkirakan: 1 - 3 hari kerja untuk pengiriman dari wilayah Jabodetabek dan 2 - 6 hari kerja untuk luar Jabodetabek.
                                                </li>
                                                <li>
                                                    Pengembalian ongkos kirim yang memenuhi beberapa syarat di atas adalah maksimal Rp 20.000.
                                                </li>
                                            </ol>
                                        </div>
                                    </div>
                                </>
                            )}

                            {activeSection === 'payment' && (
                                <>
                                    <h2 className={styles.faqItemTitle} id={"payment"}>Payment</h2>
                                    <div className={styles.faqItem}>
                                        <div className={styles.faqQuestion}>
                                            Q : Apa saja metode pembayaran yang digunakan?
                                        </div>
                                        <div className={styles.faqAnswer}>
                                            A : Anda dapat menyelesaikan pesanan Anda dengan menggunakan salah satu pilihan pembayaran berikut:
                                            <br/><br/>
                                            Virtual Account : BCA, Bank Mandiri, BNI, Bank Danamon, Bank Permata, MayBank<br/>
                                            Internet Banking : MayBank<br/>
                                            E-Wallet : OVO, Shopee Pay<br/>
                                            Retail Payment : Alfamart
                                        </div>
                                    </div>

                                    <div className={styles.faqItem}>
                                        <div className={styles.faqQuestion}>
                                            Q : Kemana saya dapat menanyakan isu pembayaran?
                                        </div>
                                        <div className={styles.faqAnswer}>
                                            A : Untuk pertanyaan lebih lanjut mengenai masalah pembayaran anda, dapat menghubungi Layanan Pelanggan (Customer Services) kami melalui Whatsapp di nomor : +62 811-879-191
                                            <br/><br/>
                                            Jam operasional Layanan Pelanggan (Customer Services) kami adalah sbb:
                                            <br/><br/>
                                            <strong>Senin s.d Jumat : 09:00-16:00 WIB</strong><br/>
                                            *kecuali hari Sabtu-Minggu dan hari libur Nasional
                                        </div>
                                    </div>
                                </>
                            )}

                            {activeSection === 'help' && (
                                <>
                                    <h2 className={styles.faqItemTitle} id={"help"}>Help</h2>
                                    <div className={styles.faqItem}>
                                        <div className={styles.faqAnswer}>
                                            Apabila ada keluhan lain, anda dapat menghubungi kami melalui:<br/>
                                            <br/>
                                            <i className="fa fa-whatsapp"/> : +62 811-879-191<br/>
                                            <i className="fa fa-envelope-o"/> : ecommerce@warna-mardhika.com<br/>
                                            <br/>
                                            Jam operasional Layanan Pelanggan (Customer Services) kami adalah sbb:<br/>
                                            <br/>
                                            Senin s.d Jumat : 09:00-16:00 WIB<br/>
                                            *kecuali hari Sabtu-Minggu dan hari libur Nasional<br/>
                                        </div>
                                    </div>
                                </>
                            )}

                        </div>
                    </div>
                </section>

                <ModuleNewsletter />

            </React.Fragment>
        );
    }
}

export default Faq;